const AnalysisImprovements = {
  KITCHEN_ID: 100,
  EXTERIOR_ID: 101,
  BATHROOM_ID: 102,
  WINDOW_ID: 103,
  DOOR_ID: 104,
  CARPET_ID: 105,
  INTERIOR_PAINT_ID: 106,
  EXTERIOR_PAINT_ID: 107,
  FLOORING_ID: 108,
  ROOF_ID: 109,
  CHIMNEY_ID: 110,
  GUTTER_ID: 111,
  CLADDING_ID: 112,
  DECK_ID: 113,
  PORCH_ID: 114,
  HEATING_ID: 115,
  AC_HEAT_PUMP_ID: 116,
  PLUMBING_ID: 117,
  ELECTRICAL_ID: 118,
  WALLS_ID: 119,
  CEILING_ID: 120,
  FIREPLACE_ID: 121,
  SKYLIGHT_ID: 122,
  FOUNDATION_ID: 123,
  BASEMENT_ID: 124,
  ATTIC_ID: 125,
  ALARM_ID: 126,
  DRIVEWAY_ID: 127,
  GARAGE_ID: 128,
  IRRIGATION_SPRINKLERS_ID: 129,
  LANDSCAPING_ID: 130,
  EQUIPMENT_ID: 131,
  POOL_SPA_REPAIR_ID: 132,
  OTHER_ID: 133,
};

export default AnalysisImprovements;
