const AnalysisIncomes = {
  LAUNDRY_ROOM_ID: 300,
  VENDING_MACHINES_ID: 301,
  LATE_CHARGES_ID: 302,
  DEPOSIT_FORFEITURES_ID: 303,
  SECTION_8_INCOME_ID: 304,
  INCOME_FROM_INTEREST_ID: 305,
  OTHER_INCOME_ID: 306,
};

export default AnalysisIncomes;
